 @media (max-width: 639px) { 
.custom-event-div{
  margin-bottom: 20px;
}
.custom-event-description{
  padding-right: 10px;
  font-size: 14px;
}
.custom-event-donateButton{
  padding-right: 10px;
  margin-right: 10px;
  /* font-size: 10px; */
}
.custom-complete-event-div{
  margin-left: 10px;
  margin-right: 10px;
}
.custom-event-heading{
  font-size: 40px;
}
.custom-event-circle{
  display:none;
  /* width: 110px;
  height: 110px; */
}
.custom-event-inner-heading{
  font-size: 30px;
}
.custom-date-icon{
  font-size: 23px;
}
.custom-date-text{
  margin-top: 3px;
  font-size: 18px;
  /* display: flex;
  justify-content: baseline; */
  margin-left: 3px;
}
.custom-time-icon{
  font-size: 23px;
}
.custom-time-text{
  font-size: 18px;
  margin-left: 5px;
  margin-top: 3px;
}
/* .custom-event-card{
  box-shadow: 0 6px 12px -8px rgb(239, 174, 94.2);
  box-shadow: 0 6px 12px -8px rgb(239, 174, 94.2), 0 4px 8px -2px rgb(233, 185, 125); 
} */
.custom-event-underline{
  width: 100px;
  height: 3px;
  background-color: #ff9933;
}

}
@media (max-width: 639px){
   .custom-heroSection-heading{
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 25px;
    /* display: flex;
    align-items:flex-end ;
     */
   
   }
    
}
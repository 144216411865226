@media (max-width: 639px){
.custom-AboutUs-div{
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;

}
.custom-about-heading{
    font-size: 40px;

  }
  .custom-AboutUs-discription{
    font-size: 20px;
  }
  .custom-about-circle{
    /* width: 110px;
    height: 110px; */
    display: none;
  }
  .custom-aboutUs-underline{
    width: 180px;
    height: 3px;
    background-color: #ff9933;
  }
  .custom-aboutUs-heading-div{
    margin-top: 35px;
    margin-bottom: 10px;

  }
 

}

@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="time"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 20px; /* Add some padding to the right to make space for the icon */
    position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

input[type="date"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 20px; /* Add some padding to the right to make space for the icon */
    position: relative;
}

  input[type="date"]::-webkit-calendar-picker-indicator {  /*input.type=date */
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}